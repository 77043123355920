<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1>Return & Refund Policy</h1>
          <p>Updated at 2022-05-16</p>

          <h1>Definitions and key terms</h1>
          <p>
            To help explain things as clearly as possible in this Return &
            Refund Policy, every time any of these terms are referenced, are
            strictly defined as:
          </p>
          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or
              “our,” it refers to Evdrivein, that is responsible for your
              information under this Return & Refund Policy.
            </li>
            <li>
              Customer: refers to the company, organization or person that signs
              up to use the Evdrivein Service to manage the relationships with
              your consumers or service users.
            </li>
            <li>
              Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit Evdrivein
              and use the services.
            </li>
            <li>
              Service: refers to the service provided by Evdrivein as described
              in the relative terms (if available) and on this platform.
            </li>
            <li>
              Website: site, which can be accessed via this URL:
              www.evinnepal.com
            </li>
            <li>
              You: a person or entity that is registered with Evdrivein to use
              the Services.
            </li>
          </ul>

          <h1>Return & Refund Policy</h1>
          <p>
            Thanks for shopping at Evdrivein. We appreciate the fact that you
            like to buy the stuff we build. We also want to make sure you have a
            rewarding experience while you're exploring, evaluating, and
            purchasing our products.
          </p>
          <p>
            As with any shopping experience, there are terms and conditions that
            apply to transactions at Evdrivein. We'll be as brief as our
            attorneys will allow. The main thing to remember is that by placing
            an order or making a purchase at Evdrivein, you agree to the terms
            set forth below along with Policy.
          </p>
          <p>
            If there's something wrong with the you bought, or if you are not
            happy with it, you have 2 weeks to issue a refund and return your .
          </p>
          <p>
            If you would like to return a product, the only way would be if you
            follow the next guidelines:
          </p>
          <ul></ul>
          <h1>Refunds</h1>
          <p>
            We at  ourselves to serving our customers with the best products.
            Every single product that you choose is thoroughly inspected,
            checked for defects and packaged with utmost care. We do this to
            ensure that you fall in love with our products.
          </p>
          <p>
            Sadly, there are times when we may not have the product(s) that you
            choose in stock, or may face some issues with our inventory and
            quality check. In such cases, we may have to cancel your order. You
            will be intimated about it in advance so that you don't have to
            worry unnecessarily about your order. If you have purchased via
            Online payment (not Cash on Delivery), then you will be refunded
            once our team confirms your request.
          </p>
          <p>
            We carry out thorough quality check before processing the ordered
            item. We take utmost care while packing the product. At the same
            time we ensure that the packing is good such that the items won't
            get damaged during transit. Please note that Evdrivein is not liable
            for damages that are caused to the items during transit or
            transportation.
          </p>
          <p>
            We will revise your returned product as soon as we receive it and if
            it follows the guidelines addressed above, we will proceed to issue
            a refund of your purchase. Your refund may take a couple of days to
            process but you will be notified when you receive your money.
          </p>
          <h1>Shipping</h1>
          <p>
            Evdrivein is responsible for return shipping costs. Every returning
            shipping is paid by Evdrivein, even if the item didn't have free
            shipping in the first place.
          </p>
          <h1>Your Consent</h1>
          <p>
            By using our website/app, registering an account, or making a
            purchase, you hereby consent to our Return & Refund Policy and agree
            to its terms.
          </p>

          <h1>Changes To Our Return & Refund Policy</h1>
          <p>
            Should we update, amend or make any changes to this document so that
            they accurately reflect our Service and policies. Unless otherwise
            required by law, those changes will be prominently posted here.
            Then, if you continue to use the Service, you will be bound by the
            updated Return & Refund Policy. If you do not want to agree to this
            or any updated Return & Refund Policy, you can delete your account.
          </p>

          <h1>Contact Us</h1>
          <p>
            If, for any reason, You are not completely satisfied with any good
            or service that we provide, don't hesitate to contact us and we will
            discuss any of the issues you are going through with our product.
          </p>
          <ul>
            <li>
              Via Email:
              <a
                href="/cdn-cgi/l/email-protection"
                class="__cf_email__"
                data-cfemail="62111712120d10162207140b0c0c0712030e4c010d0f"
                >[email&#160;protected]</a
              >
            </li>
            <li>Via this Link: www.evinnepal.com</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "RefundnReturn",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
</style>