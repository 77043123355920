import { render, staticRenderFns } from "./RefundnReturn.vue?vue&type=template&id=122aab52&scoped=true&"
import script from "./RefundnReturn.vue?vue&type=script&lang=js&"
export * from "./RefundnReturn.vue?vue&type=script&lang=js&"
import style0 from "./RefundnReturn.vue?vue&type=style&index=0&id=122aab52&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122aab52",
  null
  
)

export default component.exports